@import "../../common/colours.scss";

.check-box-parent-box {
  display: flex;
}

.check-box {
  width: 24px;
  height: 24px;
  border-radius: 2px;
  border: 1px solid $border-grey;
  margin-left: 20px;
}

.check-box-lable {
  font-size: 18px;
  color: $grey-text;
  margin-left: 8px;
  margin-top: 3px;
}
