@import "../../common/colours.scss";

.add-card-screen-modal {
  flex: 1;
  background-color: white;
  margin-bottom: 40%;
  margin-right: 20px;
  left: 20px;
  right: 10px;
  position: absolute;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
}

.add-card-screen-checkbox {
  margin-bottom: 20px;
  margin-left: 14px;
}

.add-payment-form {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 20px;
}

.wpwl-form {
  background: white;
}

.wpwl-form-card {
  box-shadow: unset !important;
  webkit-box-shadow: unset !important;
}

.wpwl-group-registration.wpwl-selected {
  padding-top: 15px;
}

.wpwl-wrapper-registration-details {
  padding-left: 20px;
}

.wpwl-button-pay {
  background-color: $base-orange;
  border-color: $base-orange;
}

.wpwl-button-pay :hover {
  background-color: $base-orange;
  border-color: $base-orange;
}
