@import "../../common/colours.scss";

.balance-box {
  flex: 1;
  background-color: white;
  height: 220px;
  margin-top: -80px;
  border-radius: 10px;
  margin-bottom: 16px;
  display: flex;
}

.balance-box-child {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.balance-box-heading-text {
  font-size: 22px;
  color: $grey-text;
  text-align: center;
  padding-top: 40px;
}

.balance-box-value-text {
  font-size: 26px;
  color: $grey-text;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 40px;
  font-weight: 600;
}

.balance-box-divider {
  width: 2px;
  background-color: $base-orange;
  height: 190px;
  margin-top: 15px;
}
