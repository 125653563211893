@import "../../common/colours.scss";

.header-box {
  height: 200px;
  background-color: $base-orange;
  display: flex;
}

.header-logo-box {
  display: flex;
  flex: 1;
  justify-content: center;
}

.header-logo {
  height: 60px;
  margin-top: 30px;
}

.tooltip-icon {
  background-color: transparent;
  position: absolute;
  right: 25px;
  top: 45px;
  text-align: center;
  font-weight: bolder;
}

.tooltip-i {
  margin: 6px 0px;
  display: block;
}

.bubble {
  position: absolute;
  top: 65px;
  right: 85px;
  background-color: #fff;
  width: 250px;
  color: #696969;
  padding: 10px;
  box-shadow: 0px 3px 7px 1px #E3E3E3;
}

.bubble:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 24px solid #fff;
  border-right: 12px solid transparent;
  border-top: 12px solid #fff;
  border-bottom: 20px solid transparent;
  top: 0;
  right: -25px;
}