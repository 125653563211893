@import "../../common/colours.scss";

.content-box {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 8px;
  margin-right: 8px;
}

.payment-form {
  display: flex;
  width: 100%;
}

.account-selection-box {
  margin-left: 16px;
  margin-right: 16px;
}

.account-number-parent-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-bottom: 20px;
}

.account-number-text {
  color: $light-grey-text;
  text-align: left;
  font-weight: 600;
  margin-left: 25px;
}

.account-number-value-text {
  color: $grey-text;
  font-weight: 700;
  margin-left: 25px;
}

.reference-number-value-text {
  color: $grey-text;
  font-weight: 700;
  text-align: right;
  margin-right: 25px;
}

.reference-number-text {
  color: $light-grey-text;
  text-align: right;
  font-weight: 600;
  margin-right: 25px;
}

.bottom-logo-box {
  align-items: center;
  justify-content: center;
  display: flex;
  margin-top: 80px;
}

.bottom-logo {
  align-self: center;
}
